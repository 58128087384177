import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-form-textarea',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    ReactiveFormsModule
  ],
  templateUrl: './form-textarea.component.html',
  styleUrl: './form-textarea.component.scss'
})
export class FormTextareaComponent {

  @Input() placeholder: string = '';
  @Input() fCN: string = '';
  @Input() fG: FormGroup = new FormGroup({});
  @Input() errorText: string = '';
  @Input() style: 'inline'|'block'|'headless' = 'headless';

  @Output() blurred: EventEmitter<void> = new EventEmitter<void>();
  @Output() returnKeyPress: EventEmitter<void> = new EventEmitter<void>();

  public onReturnKeyPress(): void {
    this.returnKeyPress.emit();
  }

}
