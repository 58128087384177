import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-headline',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './headline.component.html',
  styleUrl: './headline.component.scss'
})
export class HeadlineComponent {

  @Input() type: string = 'h5-left-semibold';
  @Input() title: string = '';

}
