import {Component, Input} from '@angular/core';
import {TranslatePipe} from "../../pipes/translate.pipe";
import {AsyncPipe, NgIf} from "@angular/common";
import {LoadingService} from "../../services/loading.service";
import {ButtonModule} from "@syncfusion/ej2-angular-buttons";

@Component({
  selector: 'app-form-button',
  standalone: true,
  imports: [
    TranslatePipe,
    AsyncPipe,
    ButtonModule,
    NgIf
  ],
  templateUrl: './form-button.component.html',
  styleUrl: './form-button.component.scss'
})
export class FormButtonComponent {

  @Input() text: string = '';
  @Input() disabled: boolean = false;
  @Input() style: 'inline' | 'block' = 'inline';
  @Input() cssClass: string = '';
  @Input() type: 'button' | 'submit' | 'reset' = 'button';

  constructor(
    public loadingService: LoadingService,
  ) {
  }

}
