import { ChangeDetectionStrategy, Component, ContentChild, ContentChildren, Directive, ElementRef, Injector, NgModule, Renderer2, ViewContainerRef } from '@angular/core';
import { ArrayBase, ComplexBase, ComponentBase, ComponentMixins, Template, setValue } from '@syncfusion/ej2-angular-base';
import { Message, Skeleton, Toast } from '@syncfusion/ej2-notifications';
import { CommonModule } from '@angular/common';
let input = ['model'];
let outputs = ['click'];
/**
 * 'e-button' directive represent a button of angular toast
 * It must be contained in a Toast component(`ejs-toast`).
 * ```html
 * <ejs-toast id='toast' showCloseIcon=true>
 *   <e-buttons>
 *    <e-button content='Ok' isPrimary=true></e-button>
 *    <e-button content='Cancel'></e-button>
 *   </e-buttons>
 * </ejs-toast>
 * ```
 */
class ButtonModelPropDirective extends ComplexBase {
  /**
   * @param {?} viewContainerRef
   */
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs);
    this.directivePropList = input;
  }
}
ButtonModelPropDirective.decorators = [{
  type: Directive,
  args: [{
    selector: 'e-buttonmodelprops>e-buttonmodelprop',
    inputs: input,
    outputs: outputs,
    queries: {}
  }]
}];
/**
 * @nocollapse
 */
ButtonModelPropDirective.ctorParameters = () => [{
  type: ViewContainerRef
}];
/**
 * ButtonModelProp Array Directive
 */
class ButtonModelPropsDirective extends ArrayBase {
  constructor() {
    super('buttons');
  }
}
ButtonModelPropsDirective.decorators = [{
  type: Directive,
  args: [{
    selector: 'ejs-toast>e-buttonmodelprops',
    queries: {
      children: new ContentChildren(ButtonModelPropDirective)
    }
  }]
}];
/**
 * @nocollapse
 */
ButtonModelPropsDirective.ctorParameters = () => [];
var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
const inputs = ['animation', 'buttons', 'content', 'cssClass', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'extendedTimeout', 'height', 'icon', 'locale', 'newestOnTop', 'position', 'progressDirection', 'showCloseButton', 'showProgressBar', 'target', 'template', 'timeOut', 'title', 'width'];
const outputs$1 = ['beforeClose', 'beforeOpen', 'beforeSanitizeHtml', 'click', 'close', 'created', 'destroyed', 'open'];
const twoWays = [''];
/**
 * Represents the Angular Toast Component
 * ```html
 * <ejs-toast></ejs-toast>
 * ```
 */
let ToastComponent = class ToastComponent extends Toast {
  /**
   * @param {?} ngEle
   * @param {?} srenderer
   * @param {?} viewContainerRef
   * @param {?} injector
   */
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['buttons'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$1);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  /**
   * @return {?}
   */
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  /**
   * @return {?}
   */
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  /**
   * @return {?}
   */
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  /**
   * @return {?}
   */
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childButtons;
    this.containerContext.ngAfterContentChecked(this);
  }
};
ToastComponent.decorators = [{
  type: Component,
  args: [{
    selector: 'ejs-toast',
    inputs: inputs,
    outputs: outputs$1,
    template: `<ng-content ></ng-content>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {
      childButtons: new ContentChild(ButtonModelPropsDirective)
    }
  }]
}];
/**
 * @nocollapse
 */
ToastComponent.ctorParameters = () => [{
  type: ElementRef
}, {
  type: Renderer2
}, {
  type: ViewContainerRef
}, {
  type: Injector
}];
ToastComponent.propDecorators = {
  'title': [{
    type: ContentChild,
    args: ['title']
  }],
  'content': [{
    type: ContentChild,
    args: ['content']
  }],
  'template': [{
    type: ContentChild,
    args: ['template']
  }]
};
__decorate([Template(), __metadata("design:type", Object)], ToastComponent.prototype, "title", void 0);
__decorate([Template(), __metadata("design:type", Object)], ToastComponent.prototype, "content", void 0);
__decorate([Template(), __metadata("design:type", Object)], ToastComponent.prototype, "template", void 0);
ToastComponent = __decorate([ComponentMixins([ComponentBase]), __metadata("design:paramtypes", [ElementRef, Renderer2, ViewContainerRef, Injector])], ToastComponent);

/**
 * NgModule definition for the Toast component.
 */
class ToastModule {}
ToastModule.decorators = [{
  type: NgModule,
  args: [{
    imports: [CommonModule],
    declarations: [ToastComponent, ButtonModelPropDirective, ButtonModelPropsDirective],
    exports: [ToastComponent, ButtonModelPropDirective, ButtonModelPropsDirective]
  }]
}];
/**
 * @nocollapse
 */
ToastModule.ctorParameters = () => [];

/**
 * NgModule definition for the Toast component with providers.
 */
class ToastAllModule {}
ToastAllModule.decorators = [{
  type: NgModule,
  args: [{
    imports: [CommonModule, ToastModule],
    exports: [ToastModule],
    providers: []
  }]
}];
/**
 * @nocollapse
 */
ToastAllModule.ctorParameters = () => [];
var __decorate$1 = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata$1 = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
const inputs$1 = ['content', 'cssClass', 'enablePersistence', 'enableRtl', 'locale', 'severity', 'showCloseIcon', 'showIcon', 'variant', 'visible'];
const outputs$2 = ['closed', 'created', 'destroyed'];
const twoWays$1 = [''];
/**
 * The Angular Message component displays messages with severity by differentiating icons and colors to denote the importance and context of the message to the end user.
 * ```html
 * <ejs-message id='msg' content='Editing is restricted' [showCloseIcon]='true'></ejs-message>
 * ```
 */
let MessageComponent = class MessageComponent extends Message {
  /**
   * @param {?} ngEle
   * @param {?} srenderer
   * @param {?} viewContainerRef
   * @param {?} injector
   */
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$2);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  /**
   * @return {?}
   */
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  /**
   * @return {?}
   */
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  /**
   * @return {?}
   */
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  /**
   * @return {?}
   */
  ngAfterContentChecked() {
    this.containerContext.ngAfterContentChecked(this);
  }
};
MessageComponent.decorators = [{
  type: Component,
  args: [{
    selector: 'ejs-message',
    inputs: inputs$1,
    outputs: outputs$2,
    template: `<ng-content ></ng-content>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {}
  }]
}];
/**
 * @nocollapse
 */
MessageComponent.ctorParameters = () => [{
  type: ElementRef
}, {
  type: Renderer2
}, {
  type: ViewContainerRef
}, {
  type: Injector
}];
MessageComponent.propDecorators = {
  'content': [{
    type: ContentChild,
    args: ['content']
  }]
};
__decorate$1([Template(), __metadata$1("design:type", Object)], MessageComponent.prototype, "content", void 0);
MessageComponent = __decorate$1([ComponentMixins([ComponentBase]), __metadata$1("design:paramtypes", [ElementRef, Renderer2, ViewContainerRef, Injector])], MessageComponent);

/**
 * NgModule definition for the Message component.
 */
class MessageModule {}
MessageModule.decorators = [{
  type: NgModule,
  args: [{
    imports: [CommonModule],
    declarations: [MessageComponent],
    exports: [MessageComponent]
  }]
}];
/**
 * @nocollapse
 */
MessageModule.ctorParameters = () => [];

/**
 * NgModule definition for the Message component with providers.
 */
class MessageAllModule {}
MessageAllModule.decorators = [{
  type: NgModule,
  args: [{
    imports: [CommonModule, MessageModule],
    exports: [MessageModule],
    providers: []
  }]
}];
/**
 * @nocollapse
 */
MessageAllModule.ctorParameters = () => [];
var __decorate$2 = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata$2 = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
const inputs$2 = ['cssClass', 'enablePersistence', 'enableRtl', 'height', 'label', 'locale', 'shape', 'shimmerEffect', 'visible', 'width'];
const outputs$3 = [];
const twoWays$2 = [''];
/**
 * Represents the Angular Skeleton component
 * ```html
 * <ejs-skeleton></ejs-skeleton>
 * ```
 */
let SkeletonComponent = class SkeletonComponent extends Skeleton {
  /**
   * @param {?} ngEle
   * @param {?} srenderer
   * @param {?} viewContainerRef
   * @param {?} injector
   */
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$3);
    this.addTwoWay.call(this, twoWays$2);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  /**
   * @return {?}
   */
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  /**
   * @return {?}
   */
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  /**
   * @return {?}
   */
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  /**
   * @return {?}
   */
  ngAfterContentChecked() {
    this.context.ngAfterContentChecked(this);
  }
};
SkeletonComponent.decorators = [{
  type: Component,
  args: [{
    selector: 'ejs-skeleton',
    inputs: inputs$2,
    outputs: outputs$3,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {}
  }]
}];
/**
 * @nocollapse
 */
SkeletonComponent.ctorParameters = () => [{
  type: ElementRef
}, {
  type: Renderer2
}, {
  type: ViewContainerRef
}, {
  type: Injector
}];
SkeletonComponent = __decorate$2([ComponentMixins([ComponentBase]), __metadata$2("design:paramtypes", [ElementRef, Renderer2, ViewContainerRef, Injector])], SkeletonComponent);

/**
 * NgModule definition for the Skeleton component.
 */
class SkeletonModule {}
SkeletonModule.decorators = [{
  type: NgModule,
  args: [{
    imports: [CommonModule],
    declarations: [SkeletonComponent],
    exports: [SkeletonComponent]
  }]
}];
/**
 * @nocollapse
 */
SkeletonModule.ctorParameters = () => [];

/**
 * NgModule definition for the Skeleton component with providers.
 */
class SkeletonAllModule {}
SkeletonAllModule.decorators = [{
  type: NgModule,
  args: [{
    imports: [CommonModule, SkeletonModule],
    exports: [SkeletonModule],
    providers: []
  }]
}];
/**
 * @nocollapse
 */
SkeletonAllModule.ctorParameters = () => [];

/**
 * Generated bundle index. Do not edit.
 */

export { ButtonModelPropDirective, ButtonModelPropsDirective, ToastComponent, ToastModule, ToastAllModule, MessageComponent, MessageModule, MessageAllModule, SkeletonComponent, SkeletonModule, SkeletonAllModule, inputs$1 as ɵc, outputs$2 as ɵd, inputs$2 as ɵe, outputs$3 as ɵf, inputs as ɵa, outputs$1 as ɵb };
export { ToastPosition, ButtonModelProps, ToastAnimations, ToastAnimationSettings, Toast, ToastUtility, Severity, Variant, Message, SkeletonType, ShimmerEffect, Skeleton } from '@syncfusion/ej2-notifications';
