import {Component} from '@angular/core';
import {LinkComponent} from "../link/link.component";
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-bar-footer',
  standalone: true,
  imports: [
    LinkComponent,
    NgOptimizedImage
  ],
  templateUrl: './bar-footer.component.html',
  styleUrl: './bar-footer.component.scss'
})
export class BarFooterComponent {

}
