import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {NgClass, NgIf} from "@angular/common";

import {TextBoxModule} from '@syncfusion/ej2-angular-inputs';
import {TooltipModule} from "@syncfusion/ej2-angular-popups";

@Component({
  selector: 'app-form-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgClass,
    TextBoxModule,
    TooltipModule,
  ],
  templateUrl: './form-input.component.html',
  styleUrl: './form-input.component.scss'
})
export class FormInputComponent implements OnInit {

  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() fCN: string = '';
  @Input() fG: FormGroup = new FormGroup({});
  @Input() label: string = '';
  @Input() errorText: string = '';
  @Input() iconFront: string = '';
  @Input() iconBack: string = '';
  @Input() iconClickType:  'none'|'front'|'back'|'both' = 'none';
  @Input() iconFrontTooltip: string = '';
  @Input() iconBackTooltip: string = '';
  @Input() style: 'inline'|'block'|'headless' = 'headless';
  @Input() disabled: boolean = false;

  @Output() blurred: EventEmitter<void> = new EventEmitter<void>();
  @Output() returnKeyPress: EventEmitter<void> = new EventEmitter<void>();
  @Output() iconFrontClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() iconBackClick: EventEmitter<void> = new EventEmitter<void>();

  public constructor() {
  }

  public ngOnInit(): void {
    if (this.disabled) {
      this.fG.controls[this.fCN].disable();
    }
  }

  public onReturnKeyPress(): void {
    this.returnKeyPress.emit();
  }

  public onIconFrontClicked(): void {
    if (this.iconClickType === 'front' || this.iconClickType === 'both') {
      this.iconFrontClick.emit();
    }
  }

  public onIconBackClicked(): void {
    if (this.iconClickType === 'back' || this.iconClickType === 'both') {
      this.iconBackClick.emit();
    }
  }

  public onBlur(): void {
    if (!this.disabled) {
      this.blurred.emit();
    }
  }
}
