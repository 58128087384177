import {Injectable} from '@angular/core';
import {ToastComponent, ToastUtility} from "@syncfusion/ej2-angular-notifications";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  private showInMilliseconds = 5000;
  private toastObj: ToastComponent | null = null;

  constructor() {

  }

  public info(message: string): void {
    this.toastObj = ToastUtility.show(message, 'Information', this.showInMilliseconds) as ToastComponent;
  }

  public success(message: string): void {
    this.toastObj = ToastUtility.show(message, 'Success', this.showInMilliseconds) as ToastComponent;
  }

  public warning(message: string): void {
    this.toastObj = ToastUtility.show(message, 'Warning', this.showInMilliseconds) as ToastComponent;
  }

  public error(message: string): void {
    this.toastObj = ToastUtility.show(message, 'Error', this.showInMilliseconds) as ToastComponent;
  }
}
