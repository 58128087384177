import {Pipe, PipeTransform} from '@angular/core';
import LinkifyIt from 'linkify-it';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Pipe({
  name: 'linkify',
  standalone: true
})
export class LinkifyPipe implements PipeTransform {

  private linkify = new LinkifyIt();

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  public transform(value: string): SafeHtml {
    if (!value) {
      return value;
    }

    const matches = this.linkify.match(value);

    if (!matches) {
      return value;
    }

    let result = value;
    matches.forEach((match: { url: any; }) => {
      const url = match.url;
      const anchorTag = '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
      result = result.replace(url, anchorTag);
    });

    return this.sanitizer.bypassSecurityTrustHtml(result);
  }
}
