<ng-container *ngIf="style === 'dashboard' && orientation === 'bottomRight'">
  <div class="dash-icon d-flex flex-column align-items-center">
    <div class="icon-container bottom-right position-relative">
      <div class="background-layer"></div>
      <div class="foreground-layer">
        <div class="icon">
          <span class="mts-icon">{{ icon }}</span>
        </div>
      </div>
      <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">{{ badge }}</span>
    </div>
    <div class="icon-text text-center">{{ title }}</div>
  </div>
</ng-container>

<ng-container *ngIf="style === 'barTop'">
  <button type="button" class="btn btn-link p-1 text-muted position-relative">
    <span class="mts-icon {{ danger ? 'text-danger' : '' }}">{{ icon }}</span>
    <span *ngIf="alert" class="badge-dot position-absolute top-0 translate-middle p-1 bg-primary border border-light rounded-circle">
      <span class="visually-hidden">{{ screenReaderText }}</span>
    </span>
  </button>
</ng-container>
