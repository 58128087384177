import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from "../services/language.service";

@Pipe({
  name: 'translate',
  standalone: true
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private languageService: LanguageService,
  ) {
  }

  transform(value: string): string {
    return this.languageService.getTranslationByKey(value);
  }

}
